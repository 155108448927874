// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-js": () => import("./../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inzerce-js": () => import("./../src/pages/inzerce.js" /* webpackChunkName: "component---src-pages-inzerce-js" */),
  "component---src-pages-kontrola-prispevku-js": () => import("./../src/pages/kontrola-prispevku.js" /* webpackChunkName: "component---src-pages-kontrola-prispevku-js" */),
  "component---src-pages-pravidla-js": () => import("./../src/pages/pravidla.js" /* webpackChunkName: "component---src-pages-pravidla-js" */),
  "component---src-pages-vyhledavani-informaci-js": () => import("./../src/pages/vyhledavani-informaci.js" /* webpackChunkName: "component---src-pages-vyhledavani-informaci-js" */),
  "component---src-pages-zalozeni-dotazu-js": () => import("./../src/pages/zalozeni-dotazu.js" /* webpackChunkName: "component---src-pages-zalozeni-dotazu-js" */),
  "component---src-templates-page-template-js": () => import("./../src/templates/pageTemplate.js" /* webpackChunkName: "component---src-templates-page-template-js" */)
}

